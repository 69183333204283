
import {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxPaging,
    DxGroupPanel,
    DxGrouping,
    DxForm,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxValidationRule,
    DxFormItem
} from 'devextreme-vue/data-grid';
import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
import 'devextreme-vue/text-area';

import OrdenTrabajoResultadoService from '../services/ordentrabajoresultado-service';




export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,
        DxGroupPanel,
        DxGrouping,
        DxForm,
        DxItem,
        DxFilterRow,
        DxSearchPanel,
        DxScrolling,
        DxPager,
        DxToast,
        DxValidationRule,
        DxFormItem
    },
    props: {
        supplierId: {
            type: Number,
            default: null,
        },
    },
    data() {

        return {

            procedimientos: null,
            dataSourceEstandar: null,
            dataSourceCapitulo: null,
            dataSourceCumplimiento: null,
            allowedPageSizes: [5, 10, 20, 50, 'all'],
            showInfo: true,
            showNavButtons: true,
            isVisible: false,
            message: '',
            type: 'info',
            popupOptions: {
                title: 'Resultados',
                showTitle: true,
                width: 650,
                height: 650,
            },



        };
    },
    created() {
        this.ordentrabajoresultadoService = new OrdenTrabajoResultadoService();


    },
    mounted() {

        this.loadDataInfo();
    },
    methods: {
        loadDataInfo() {



            let id = this.supplierId;

            let myJSON = JSON.stringify({
                pid: id,

            });

            this.ordentrabajoresultadoService.getOrdenTrabajoResultadoDetalle(myJSON).then(data => {
                data.forEach(
                    element => {

                        if (element.valorsi == 1) {
                            element.valorsi = true;
                        } else {
                            element.valorsi = false;
                        }
                        if (element.valorno == 1) {
                            element.valorno = true;
                        } else {
                            element.valorno = false;
                        }
                        if (element.valorparcial == 1) {
                            element.valorparcial = true;
                        } else {
                            element.valorparcial = false;
                        }
                        if (element.noaplica == 1) {
                            element.noaplica = true;
                        } else {
                            element.noaplica = false;
                        }

                    });
                this.dataSourceEstandar = data;

            });

        },
        onSaved() {
            if (this.retryButtonVisible) {
                this.retryButtonVisible = false;
            }
        },
        onEditCanceled() {
            if (this.retryButtonVisible) {
                this.retryButtonVisible = false;
            }
        },
        logEvent(eventName) {
            this.events.unshift(eventName);
        },

        deleteRegister(e) {

            let myJSON = JSON.stringify({
                pid: e.data.id,
            });


            this.GuiaEvaluacionEstandarService.deleteGuiaEvaluacionEstandar(myJSON).then(() => {
                this.type = 'success';
                this.message = 'Se ha eliminado el registro';
                this.isVisible = true;
                this.loadDataInfo();
            });

        },
        editRegister(e) {


            let myJSON = JSON.stringify({
                pid: e.data.id,
                pguiaevaluacioncapituloid: e.data.guiaevaluacioncapituloid,
                pdescripcion: e.data.descripcion,
                porden: e.data.orden,
                pcumplimientoid: e.data.cumplimientoid,
            });


            this.GuiaEvaluacionEstandarService.updateGuiaEvaluacionEstandar(myJSON).then(() => {
                this.type = 'success';
                this.message = 'Se ha actualizado el registro';
                this.isVisible = true;
                this.loadDataInfo();
            });

        },
        createRegister(e) {

            let myJSON = JSON.stringify({
                pguiaevaluacioncapituloid: e.data.guiaevaluacioncapituloid,
                pdescripcion: e.data.descripcion,
                porden: e.data.orden,
                pcumplimientoid: e.data.cumplimientoid,
            });


            this.GuiaEvaluacionEstandarService.createGuiaEvaluacionEstandar(myJSON).then(() => {
                this.type = 'success';
                this.message = 'Se ha creado el registro';
                this.isVisible = true;
                this.loadDataInfo();
            });

        },
        onInitNewRow(e) {
            e.data.bactivo = true;
        },
    },
};
