
import { DxTabPanel, DxItem } from 'devextreme-vue/tab-panel';

import HabilidadTab from './ordentrabajoresultadodetalle.vue';
import ListadoTab from './ordentrabajoresultadolistadodocumentacion.vue';
import InfTab from './ordentrabajoresultadolistadoinformacion.vue';
import DocTab from './cotizaciondocumentacion.vue';
export default {
  components: {
    DxTabPanel, DxItem, HabilidadTab, ListadoTab, DocTab, InfTab
  },
  props: {
    masterDetailData: {
      type: Object,
      default: () => ({}),
    },
  },
};
